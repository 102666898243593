import axios from "axios"
import { siteMetadata } from '../../gatsby-config'


function BuildMenuItems(items) {
  let menuItems = [];
  items.map((menuItem) => {
    menuItems.push({
      text: menuItem.NombreCategoria,
      href: "/play/categoria/" + menuItem.IdCategoria,
      submenu: menuItem.Hijos ? BuildMenuItems(menuItem.Hijos) : null
    })
  })
  return menuItems;
}

function recibirMenu(menuItems, isFromMobileApp) {
  return {
    type: "GETMENU",
    menuItems,
    isFromMobileApp
  }
}

function fetchMenu(dispatch){
  let baseurl = siteMetadata.apiBaseURL;
  let url = baseurl + "/App_HttpHandlers/MobileAppHandler.ashx?accion=search&TipoDeBusqueda=PROPIEDAD&Profundidad=1&Propiedad=ordenMenu"
  axios
    .get(url)
    .then(({ data }) => {
      let menuItems = data.Hijos ? BuildMenuItems(data.Hijos) : [];
      let isFromMobileApp =  (data.IsFromMobileApp !== null ? data.IsFromMobileApp : false);
      dispatch(recibirMenu(menuItems, isFromMobileApp))
    })
    .catch(error => {
      console.log(error);
    })
}


export default function nav(state = {
  mostrarNavBar: true,
  timeoutHide: false,
  MenuItems: [],
  isFromMobileApp: false
}, action) {
  switch (action.type) {
    case "REQUESTMENU":
      fetchMenu(action.asyncDispatch)
      return {
        ...state
      }
    case "GETMENU":
      return {
        ...state,
        MenuItems: action.menuItems,
        isFromMobileApp: action.isFromMobileApp
      }
    case 'SHOWNAV':
      return {
        ...state,
        mostrarNavBar: true
      }
    case 'HIDENAV':
      return {
        ...state,
        mostrarNavBar: false
      }
    case 'HIDENAVBYSCROLL':
      return {
        ...state,
        timeoutHide: false
      }
    case 'HIDENAVBYTIMEOUT':
      return {
        ...state,
        timeoutHide: true
      }
    default:
      return state
  }
}
