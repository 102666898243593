import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { siteMetadata } from '../../../gatsby-config'
import axios from "axios"
import { navigate } from "gatsby"

import { Tabs, Tab } from 'react-bootstrap'
import ArbolCategorias from "../Arbol/ArbolCategorias"
import UploadMedios from "./UploadMedio"
import { ATRIBUTOPREFIX } from "../../utils/constantes"
import { DatePickerInput } from 'rc-datepicker';

import Propiedad from "./Propiedad";
import Atributo from "./Atributo";

import '../../scss/rc-datepicker-style.css';
import 'moment/locale/es.js'

const qs = require('querystring');
const moment = require("moment");

const baseurl = siteMetadata.apiBaseURL;

//Para Biblioflix el estado inicial es properties porque Upload esta deshabilitado -> activeKey: "files",
const INITIALSTATE = {
  activeKey: "properties",
  esMedioNuevo: true,
  medioLoaded: false,
  saving: false,
  medioUploaded: null,
  thumbnailUploaded: null,
  medio: {
    Publicacion: moment().format(),
    atributos: {},
    validField: {}
  }
}


export default function ({ medio, GuardarButton }) {
  const [editMedioState, setEditMedioState] = useState(INITIALSTATE)
  let currentMedio = medio;
  const [lastSaving, setLastSaving] = useState(0);
  const requeridos = ["Titulo", "Descriptores", "Sinopsis", "Duracion", "Publicacion", "Responsable"]


  useEffect(() => {
    if (GuardarButton !== undefined && GuardarButton.current) {
      GuardarButton.current.disabled = editMedioState.saving || !TodoValido()
      GuardarButton.current.innerText = editMedioState.saving ? "Guardando..." : "Guardar";
      GuardarButton.current.onclick = GuardarTodo
      if (!editMedioState.esMedioNuevo) {
        GuardarButton.current.className = "d-none";
      }
    }

    if (editMedioState.newMedio !== undefined) {
      currentMedio = editMedioState.newMedio
      delete editMedioState.newMedio;
    }

    if (currentMedio && !editMedioState.medioLoaded) {
      let atributos = {};
      Object.keys(currentMedio.AtributosPredefinidos).map((key) => {
        let atributo = currentMedio.AtributosPredefinidos[key];
        atributos[`${ATRIBUTOPREFIX}${atributo.Id}`] = atributo.Value;
      })

      Object.keys(currentMedio.AtributosAdicionales).map((key) => {
        let atributo = currentMedio.AtributosAdicionales[key];
        atributos[`${ATRIBUTOPREFIX}${atributo.Id}`] = atributo.Value;
      })

      currentMedio.atributos = atributos;
      currentMedio.validField = {}
      setEditMedioState({
        ...editMedioState,
        activeKey: "properties",
        esMedioNuevo: false,
        medio: currentMedio,
        medioLoaded: true
      })
    } else if (!editMedioState.medioLoaded) {
      setEditMedioState({
        ...INITIALSTATE,
        medioLoaded: true,
        medioUploaded: null,
        thumbnailUploaded: null,
        medio: {
          Publicacion: moment().format(),
          atributos: {},
          validField: {}
        }
      })
    }

    return () => {
    }

  },
    [editMedioState.medio,
    editMedioState.medioUploaded,
    editMedioState.thumbnailUploaded]);

  useEffect(() => {
    if (lastSaving != 0) {
      setLastSaving(Date.now())
    }
    else {
      setLastSaving(Date.now() - 10000)
    }

  }, [
      editMedioState.saving
    ])


  const dispatch = useDispatch()
  const { atributos } = useSelector(state => state)
  const PublicacionRef = useRef();
  /*  -------------------  */

  const postConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

  const saveProp = (propName, value) => {
    let url = baseurl + "/App_HttpHandlers/MobileAppHandler.ashx";
    let sendData = {
      accion: "UpdateMedio",
      IdMedio: editMedioState.medio.IdMedio
    }
    sendData[propName] = value;
    sendData = qs.stringify(sendData)

    setEditMedioState({
      ...editMedioState,
      saving: true
    });


    axios
      .post(url, sendData, postConfig)
      .then(({ data }) => {
        setEditMedioState({
          ...editMedioState,
          saving: false
        });

      })
      .catch(error => {
        console.log(error);
        setEditMedioState({
          ...editMedioState,
          saving: false
        });

      })
  }
  
  const saveNuevo = () => {
    let url = baseurl + "/App_HttpHandlers/MobileAppHandler.ashx";
    let sendData = {
      accion: "AddMedio",
      ...editMedioState.medio,
      medioUploaded: editMedioState.medioUploaded,
      thumbnailUploaded: editMedioState.thumbnailUploaded
    }
    delete sendData.atributos;
    delete sendData.validField;

    //console.log("saveNuevo sendData", sendData)

    Object.keys(editMedioState.medio.atributos).map((key) => {
      sendData[key] = editMedioState.medio.atributos[key];
    })

    setEditMedioState({
      ...editMedioState,
      saving: true
    });

    sendData = qs.stringify(sendData)
    axios
      .post(url, sendData, postConfig)
      .then(({ data }) => {
        setEditMedioState({
          ...INITIALSTATE,
          newMedio: data
        });
        navigate(`/play/player/${data.IdMedio}`);
        Alert("Agregar medio", "Medio agregado exitósamente");
      })
      .catch(error => {
        setEditMedioState({
          ...editMedioState,
          saving: false
        });
        console.log(error);
      })

  }

  const setActiveKey = (k) => {
    setEditMedioState({
      ...editMedioState,
      activeKey: k
    })
  }

  const handleInputChange = (e) => {
    let newMedio = {
      ...editMedioState.medio,
    }

    if (e.target.id.indexOf(ATRIBUTOPREFIX) != -1) {
      newMedio.atributos[e.target.id] = e.target.value
    } else {
      newMedio[e.target.id] = e.target.value;
    }

    setEditMedioState({
      ...editMedioState,
      medio: newMedio
    })
  }

  const handleInputBlur = (e) => {
    let newMedio = {
      ...editMedioState.medio,
    }

    let valid = e.target.validity.valid;
    newMedio.validField[e.target.id] = valid;


    setEditMedioState({
      ...editMedioState,
      medio: newMedio
    })

    if (!editMedioState.esMedioNuevo && valid) {
      saveProp(e.target.id, getPropValue(e.target.id))
    } else if (editMedioState.esMedioNuevo
      && e.target.id == "UrlExterno" && e.target.value.match(/https?:\/\/soundcloud\.com/gi)) {
      if (confirm("Desea importar los datos desde SoundCloud?")) {
        let url = baseurl + "/App_HttpHandlers/MobileAppHandler.ashx";
        axios
          .get(url, {
            params: {
              accion: "getmediofromsoundcloud",
              SoundCloudUrl: e.target.value,
            }
          })
          .then(({ data }) => {
            //console.log("getmediofromcloudsound", data);

            let newMedio = {
              ...editMedioState.medio,
            }

            newMedio.Titulo = data.Titulo;
            newMedio.Publicacion = data.Publicacion;
            newMedio.Sinopsis = data.Sinopsis;
            newMedio.Descriptores = data.Descriptores;
            newMedio.ImagenAsociada = data.ImagenAsociada;
            newMedio.Duracion = data.Duracion;

            setEditMedioState({
              ...editMedioState,
              medio: newMedio
            })

          })
          .catch(error => {
            console.log("ERROR", error);
            self.props.refArbol.props.Alert("Error",
              "Ocurrio un error al intentar extraer los datos de SoundCloud");
          })
      }
    } else if (editMedioState.esMedioNuevo
      && e.target.id == "UrlExterno" && e.target.value.match(/https?:\/\/(youtu\.be|(www\.)?youtube\.com)/gi)) {
      if (confirm("Desea importar los datos desde YouTube?")) {
        let url = baseurl + "/App_HttpHandlers/MobileAppHandler.ashx";
        axios
          .get(url, {
            params: {
              accion: "getmediofromyoutube",
              YouTubeUrl: e.target.value,
            }
          })
          .then(({ data }) => {
            //console.log("getmediofromyoutube", data);

            let newMedio = {
              ...editMedioState.medio,
            }

            newMedio.Titulo = data.Titulo;
            newMedio.Publicacion = data.Publicacion;
            newMedio.Sinopsis = data.Sinopsis;
            newMedio.Descriptores = data.Descriptores;
            newMedio.ImagenAsociada = data.ImagenAsociada;
            newMedio.Duracion = data.Duracion;

            setEditMedioState({
              ...editMedioState,
              medio: newMedio
            })

          })
          .catch(error => {
            console.log("ERROR", error);
            self.props.refArbol.props.Alert("Error",
              "Ocurrio un error al intentar extraer los datos de YouTube");
          })
      }
    }
  }

  const handleChangeDate = (jsDate, dateString) => {
    let newMedio = {
      ...editMedioState.medio,
    }

    let inputId = "Publicacion";
    let inputField = document.getElementById(inputId)


    if (inputField.value.indexOf("-") != -1) {
      inputField.value = inputField.value.replace(/-/g, "/");
      PublicacionRef.current.onChangeInput({ target: PublicacionRef.current.getDatePickerInput() });
      return;
    }

    let partes = inputField.value.split("/");
    if (partes.length == 3) {
      if (partes[0].length == 1 || partes[1].length == 1) {
        partes[0] = (partes[0].length == 1 ? "0" : null) + partes[0];
        partes[1] = (partes[1].length == 1 ? "0" : null) + partes[1];
        inputField.value = partes.join("/");
        PublicacionRef.current.onChangeInput({ target: PublicacionRef.current.getDatePickerInput() });
        return;
      }
    }



    /**/
    //dateString = inputField.value ? dateString : ""
    let isValid = newMedio.validField[inputId] = dateString != "Invalid date";

    //dateString = dateString.replace(/T.*$/gi, ""); //quitar la parte de hora
    //console.log("dateString", dateString);

    if (isValid) {
      newMedio.Publicacion = dateString
      newMedio.validField[inputId] = true;
    } else {
      newMedio.validField[inputId] = false;
    }

    setEditMedioState({
      ...editMedioState,
      medio: newMedio
    })

    if (isValid) {
      if (!editMedioState.esMedioNuevo) {
        saveProp(inputId, dateString)
      }
    }

  }

  const handleInputDateBlur = (e) => {
    let inputId = "Publicacion";
    let inputField = document.getElementById(inputId)
    let newMedio = {
      ...editMedioState.medio,
    }
    newMedio.validField[inputId] = inputField.value.length > 0
    setEditMedioState({
      ...editMedioState,
      medio: newMedio
    })
  }

  const getPropValue = (propName) => {
    let result = editMedioState.medio ? editMedioState.medio[propName] : "";
    return result === undefined || result == null ? "" : result;
  }

  const getPropDateValue = (propName) => {
    let result = editMedioState.medio ? editMedioState.medio[propName] : undefined;
    /*
    if (result !== undefined) {
      result = result && result.replace(/T.*$/gi, ""); //quitar la parte de hora
    }
    */
    console.log("getPropDateValue", result);
    return result === undefined || result == null ? new Date() : result;
  }

  const RevisarCamposRequeridos = () => {
    PublicacionRef.current.onChangeInput({ target: PublicacionRef.current.getDatePickerInput() });
    requeridos.map((id) => {
      let input = document.getElementById(id);
      editMedioState.medio.validField[id] = input.validity.valid
    })
  }

  const TodoValido = () => {
    let result = false;
    if (editMedioState.medio
      && editMedioState.medio.validField
    ) {
      let fieldKeys = Object.keys(editMedioState.medio.validField);
      result = fieldKeys.length > 0;
      fieldKeys.map((key) => {
        result &= editMedioState.medio.validField[key]
      })
    }
    return result
  }

  const ValidarUploadedMedio = () => {
    let input = document.getElementById("UrlExterno");
    return editMedioState.esMedioNuevo
      && ((input != null && input.value) || editMedioState.medioUploaded);
  }

  const ValidarUploadedThumbnail = () => {
    let input = document.getElementById("ImagenAsociada");
    return editMedioState.esMedioNuevo
      && ((input != null && input.value) || editMedioState.thumbnailUploaded);
  }

  const GuardarTodo = (e) => {
    RevisarCamposRequeridos();
    let todoOk = TodoValido();

    /* Para sinabi esta deshabiltiada la opcion de subir
    if (!ValidarUploadedMedio()) {
      setActiveKey("files");
      Alert("Debe subir un medio");
    }
    */
    /*else if (!ValidarUploadedThumbnail()) {
      setActiveKey("files");
      Alert("Debe subir una miniatura para el medio");
    }*/
    if (todoOk) {
      saveNuevo();
    } else {
      Alert("Error", "Falta completar los datos de algún campo requerido");
    }
  }

  const Alert = (title, text) => {
    dispatch({
      type: `SHOWUSERMESSAGE`, title, text
    })
  }

  const medioUploaded = (files) => {
    setEditMedioState({
      ...editMedioState,
      medioUploaded: files
    })
  }

  const thumbnailUploaded = (files) => {
    setEditMedioState({
      ...editMedioState,
      thumbnailUploaded: files
    })
  }


  if (editMedioState.medioUploaded &&
    editMedioState.medio.validField["UrlExterno"] !== undefined) {
    let newMedio = {
      ...editMedioState.medio
    }
    delete newMedio.validField["UrlExterno"];
    setEditMedioState({
      ...editMedioState,
      medio: newMedio
    })
  }

  if (editMedioState.thumbnailUploaded &&
    editMedioState.medio.validField["ImagenAsociada"] !== undefined) {
    let newMedio = {
      ...editMedioState.medio
    }
    delete newMedio.validField["ImagenAsociada"];
    setEditMedioState({
      ...editMedioState,
      medio: newMedio
    })
  }


  return (
    <>
      <h3 className={(Date.now() - lastSaving) < 2000 ? "guardando active" : "guardando"}>Guardando....</h3>
      <Tabs id="controlled-tab-example" activeKey={editMedioState.activeKey} onSelect={k => setActiveKey(k)}>
        {
          /*Para biblioflix subir archivos esta deshabilitado*/
          editMedioState.esMedioNuevo && false ?
            <Tab eventKey="files" title="Archivos">
              <div className="row">
                <div className="col">
                  <h2>Medio principal</h2>
                  <UploadMedios
                    onUpload={medioUploaded}
                    tiposAceptado="video/*,audio/*"
                    multiple={false} />
                </div>
                <div className="col">
                  <h2>Miniatura</h2>
                  <UploadMedios
                    onUpload={thumbnailUploaded}
                    tiposAceptado="image/*"
                    multiple={false} />
                </div>
              </div>

            </Tab>
            : null
        }


        <Tab eventKey="properties" title="Propiedades">

          <Propiedad id="Titulo"
            label="T&iacute;tulo"
            type="text"
            required={true}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            getPropValue={getPropValue}
            editMedioState={editMedioState}
          />

          {!editMedioState.medioUploaded ?
            <Propiedad id="UrlExterno"
              label="URL del Medio"
              type="text"
              required={true}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              getPropValue={getPropValue}
              editMedioState={editMedioState}
            />
            : null}

          {!editMedioState.thumbnailUploaded ?
            <Propiedad id="ImagenAsociada"
              label="Imagen miniatura"
              type="text"
              required={false}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              getPropValue={getPropValue}
              editMedioState={editMedioState}
            />
            : null
          }

          <Propiedad id="Descriptores"
            label="Descriptores"
            type="text"
            required={true}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            getPropValue={getPropValue}
            editMedioState={editMedioState}
          />

          <Propiedad id="Sinopsis"
            label="Sinopsis"
            type="textarea"
            rows={5}
            required={true}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            getPropValue={getPropValue}
            editMedioState={editMedioState}
          />

          <Propiedad id="Duracion"
            label="Duraci&oacute;n"
            textAfterControl=" segundos."
            type="number"
            step={1}
            required={true}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            getPropValue={getPropValue}
            editMedioState={editMedioState}
          />

          <div className="form-group row">
            <label
              htmlFor="publicacion"
              className="col-sm-3 col-form-label"
            ><span className="text-danger">*</span> <span>Fecha de publicaci&oacute;n</span></label>
            <div className="col-sm-9">
              <DatePickerInput
                ref={PublicacionRef}
                id="Publicacion"
                onChange={handleChangeDate}
                value={getPropDateValue("Publicacion")}
                className='form-control-datepicker'
                locale='es'
                validationFormat="DD/MM/YYYY"
                placeholder="día/mes/a&ntilde;o"
                onBlur={handleInputDateBlur}
              />
              {editMedioState.medio
                && editMedioState.medio.validField !== undefined
                && editMedioState.medio.validField["Publicacion"] !== undefined
                && !editMedioState.medio.validField["Publicacion"] ?
                <div className="validationMessage text-danger">
                  Formato de fecha incorrecto. día/mes/a&ntilde;o
                </div>
                : null}
            </div>
          </div>

          <Propiedad id="Responsable"
            label="Responsable de publicaci&oacute;n"
            type="text"
            required={true}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            getPropValue={getPropValue}
            editMedioState={editMedioState}
          />
          <span className="text-danger">*</span> Indica que el campo es requerido
        </Tab>
        <Tab eventKey="attributes" title="Atributos">
          {atributos.map((atributo, i) => {
            return (
              <Atributo
                key={i}
                atributo={atributo}
                onChange={handleInputChange}
                editMedioState={editMedioState}
                setEditMedioState={setEditMedioState}
                requeridos={requeridos}
              />
            )
          })}
          <span className="text-danger">*</span> Indica que el campo es requerido
        </Tab>
        <Tab eventKey="categories" title="Categorias" disabled={editMedioState.esMedioNuevo} >
          <ArbolCategorias
            medio={editMedioState.medio}
            selected={
              editMedioState.medio ?
                editMedioState.medio.CategoriasPredefinidas
                : []
            }
          />
        </Tab>
      </Tabs>
    </>
  )
}
