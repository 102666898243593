import React from 'react'
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import axios from "axios"
import { Link } from 'gatsby'
import MenuItem from "./MenuItem"
import BuscarAsignaturas from "./buscarAsignatura"
import BuscarTexto from "./BuscarTexto"
import UserMessage from "./UserMessage"
import AdminMenu from "../admin/AdminMenu"

class Navi extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    mostrarNavBar: true,
    prevScrollpos: 0,
    collapsedMenuActive: false
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.props.RequestMenu();
    this.props.ShowNav();
    if (!this.props.arbolCategorias.raiz) {
      this.props.LoadCategorias();
    }

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (event) => {
    //if (this.props.isMobile) return null;
    let maxScroll = document.body.clientHeight - window.innerHeight;

    maxScroll = maxScroll < 5 ? 0 : maxScroll;

    let currentScrollPos = window.pageYOffset;



    if (
      (maxScroll > 0 && this.state.prevScrollpos > currentScrollPos && this.state.prevScrollpos <= maxScroll)

      || (maxScroll <= 0 && this.state.prevScrollpos > currentScrollPos)
      || (this.state.prevScrollpos <= 0 && currentScrollPos <= 0)
    ) {

      if (!this.props.NavState.mostrarNavBar) {
        this.props.ShowNav();
      }

    } else {
      if (this.props.NavState.mostrarNavBar) {
        this.props.HideNav();
      }
    }
    this.setState({
      ...this.state,
      prevScrollpos: currentScrollPos
    });
  }

  GetNavBarClasses = () => {
    let result = "navbar-nav bd-navbar-nav flex-row"
    if (this.state.collapsedMenuActive) {
      result += " active";
    }
    return result;
  }

  handleBack = () => {
    if (history.state === null || history.state.page == 1) {
      //alert("Llegamos al inicio");
      this.closeMediateca();
    } else {
      history.back();
    }
  }

  closeMediateca = () => {
    location.href = "/salirdemediateca";
  }

  render() {
    //console.log("PROPS NAVI", this.props)
    const { location, title } = this.props
    const { MenuItems } = this.props.NavState
    const { mostrarNavBar, timeoutHide, isFromMobileApp } = this.props.NavState
    return (
      <>
        <UserMessage />
        <nav id="navbar"
          className={"navbar navbar-expand navbar-dark flex-column flex-md-row bg-mediatecaHeader fixed-top "
            + ((isFromMobileApp || mostrarNavBar || this.state.collapsedMenuActive) ? "active" : "")}
        >
          <div className="clearfix w-100">
            {
              isFromMobileApp ?
                <>
                  {/*<button className="btn" onClick={this.closeMediateca}>Home</>*/}
                  <button className="btn back" onClick={this.handleBack}>
                    <em class="fa fa-chevron-left"></em>
                  </button>
                </>

                :
                <>
                  <a href="/" className="SINABILogo pull-left">
                    <span className="invisible">{title}</span>
                  </a>
                  <div className="IsoContainer pull-left">Biblioteca Nacional <br></br>Costa Rica</div>
                </>
            }
            <div className={`MenuCollapse ${this.state.collapsedMenuActive ? "active" : ""}`} onClick={(e) => {
              this.setState({
                collapsedMenuActive: !this.state.collapsedMenuActive
              });
            }} ></div>
            <div className="navbar-nav-scroll pull-right mt-3">
              <ul className={this.GetNavBarClasses()}>

                {
                  isFromMobileApp ?
                    <li className='nav-item'>
                      <a className="nav-link" onClick={this.closeMediateca}>Salir de mediateca</a>
                    </li>
                    : null
                }


                <li
                  className={
                    location && location.pathname === '/' ? 'nav-item active' : 'nav-item'
                  }
                >
                  <Link to="/" className="nav-link">
                    Inicio
                  </Link>
                </li>

                {/*
                <li className="nav-item">
                  <BuscarAsignaturas {...this.props} />
                </li>
                 */}

                {MenuItems ?
                  MenuItems.map((menuItem, i) => {
                    return (
                      <MenuItem item={menuItem} key={i} />
                    )
                  })
                  :
                  null
                }
                <li className="nav-item">
                  <BuscarTexto {...this.props} />
                </li>
                <li className="nav-item">
                  <AdminMenu />
                </li>
              </ul>
            </div>
            <div className="navbar-nav flex-row ml-md-auto d-none d-md-flex" />
          </div>
        </nav>

        {
          !timeoutHide ?
            <div className="NavSrollSpan"></div>
            : null
        }

      </>
    )
  }
}


Navi.propTypes = {
  NavState: PropTypes.object.isRequired,
  ShowNav: PropTypes.func.isRequired,
  HideNav: PropTypes.func.isRequired,
}


const mapStateToProps = ({ nav, arbolCategorias }) => {
  return {
    NavState: nav
    , arbolCategorias
  }
}

const mapDispatchToProps = dispatch => {
  return {
    RequestMenu: () => {
      dispatch({ type: 'REQUESTMENU' })
    },
    ShowNav: () => {
      dispatch({ type: 'SHOWNAV' })
    },
    HideNav: () => {
      dispatch({ type: 'HIDENAV' })
    },
    LoadCategorias: (baseurl) => {
      dispatch({ type: "LOADCATEGORIAS" })
    }
  }
}

const ConnectedNavi = connect(
  mapStateToProps,
  mapDispatchToProps
)(Navi)

export default ConnectedNavi
