/* eslint-disable @typescript-eslint/camelcase */
module.exports = {
  siteMetadata: {
    title: 'BiblioFlix',
    description: 'Mediateca Biblioteca Nacional de Costa Rica',
    siteUrl: 'https://mediateca.sinabi.go.cr',
    //siteUrl: 'https://biblioflix.george.hermes-soft.com',
    author: 'jvillmo',
    twitter: '@biblioflix',
    adsense: '',
    //apiBaseURL: '//biblioflix.george.hermes-soft.com/mediateca/',
    apiBaseURL: '//mediateca.sinabi.go.cr/mediateca/',
    gtag: "desarrollo",
    trackingId: "desarrollo"
  },
  pathPrefix: '/',
  plugins: [
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/content/posts/`,
        name: 'posts',
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/content/images/`,
        name: 'images',
      },
    },
    {
      resolve: 'gatsby-transformer-remark',
      options: {
        plugins: [
          {
            resolve: 'gatsby-remark-images',
            options: {
              maxWidth: 750,
              linkImagesToOriginal: false,
              wrapperStyle: 'margin-bottom: 1.0725rem;',
            },
          },
          {
            resolve: 'gatsby-remark-responsive-iframe',
            options: {
              wrapperStyle: 'margin-bottom: 1.0725rem',
            },
          },
          'gatsby-remark-prismjs',
          'gatsby-remark-copy-linked-files',
          'gatsby-remark-smartypants',
        ],
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'Mediateca Biblioteca Nacional de Costa Rica',
        short_name: 'BiblioFlix',
        description: 'Mediateca Biblioteca Nacional de Costa Rica',
        homepage_url: 'http://biblioflix.george.hermes-soft.com/',
        start_url: '/',
        background_color: '#fff',
        theme_color: '#FFC211',
        display: 'standalone',
        icons: [
          {
            src: '/img/android-chrome-192x192.png',
            sizes: '192x192',
            type: 'image/png',
          },
          {
            src: '/img/android-chrome-512x512.png',
            sizes: '512x512',
            type: 'image/png',
          },
        ],
      },
    },
    /*
    {
      resolve: 'gatsby-plugin-google-analytics',
      options: {
        trackingId: 'G-32444TBVWZ',
      },
    },

*/
    /*
    {
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        trackingIds: [
          "GTM-M9R36VZ" // Google Analytics / GA
          //"UA-151634811-1" // Google Analytics / GA
        ],

        gtagConfig: {
          send_page_view: false,
          anonymize_ip: true,
          cookie_expires: 0,
        },

        // This object is used for configuration specific to this plugin
        pluginConfig: {
          // Puts tracking script in the head instead of the body
          head: false,
          respectDNT: false
        },
      },
    },    
    */

    {
      resolve: 'gatsby-plugin-netlify',
      options: {
        mergeSecurityHeaders: true,
        mergeLinkHeaders: true,
        mergeCachingHeaders: true,
      },
    },
    'gatsby-plugin-catch-links',
  /*'gatsby-plugin-offline',*/
/*  'gatsby-plugin-remove-serviceworker',*/
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-sass',
    'gatsby-plugin-sharp',
    'gatsby-plugin-sitemap',
    'gatsby-plugin-twitter',
    "gatsby-plugin-typegen",
    'gatsby-plugin-typescript',
    'gatsby-transformer-sharp',
  ],
}
