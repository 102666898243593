import React from 'react'
import { connect } from "react-redux"
import axios from "axios"
import { siteMetadata } from '../../../gatsby-config'
import { Link } from 'gatsby'
import MenuItem from "../navi/MenuItem"
import Login from "../Login"


class Footer extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
  }

  componentDidUpdate() {
  }

  render() {
    const { showFooter } = this.props
    const { MenuItems } = this.props;

    return (
      <>
        {
          showFooter ?
            <div className="footer">
              <div className="menuFooterContainer py-3">
                <div className="d-flex bd-highlight">
                  <div className="p-2 flex-grow-1 bd-highlight">
                    <ul className="">
                      {/*
                      <li className="nav-item">
                        <a className="nav-link" onClick={() => { this.props.ShowModalAsignaturas() }} >Asignaturas</a>
                      </li>
                      */}
                      {/*MenuItems ?
                        MenuItems.map((item, i) => {
                          return (
                            <MenuItem item={item} key={i} />
                          )
                        })
                        :
                        null
                      */}


                      <li className="nav-item">
                        <Login/>
                      </li>

                    </ul>
                  </div>
                  {/* REDES SOCIALES
                  <div className="p-2 bd-highlight align-self-center redesContainer">
                    <a href="https://www.instagram.com/bibliotecaNacional/" target="_blank"><em className="m-2 fa fa-instagram"></em><span className="d-none">instagram</span></a>
                    <a href="https://www.facebook.com/bibliotecaNacional/" target="_blank"><em className="m-2 fa fa-facebook-square"></em><span className="d-none">facebook</span></a>
                    <a href="https://www.youtube.com/channel/bibliotecaNacional" target="_blank"><em className="m-2 fa fa-youtube"></em><span className="d-none">youtube</span></a>
                  </div>
                  */}
                </div>
              </div>
              <div className="footerInfoContainer py-3">
                <div className="container">                
                  <div className="d-flex bd-highlight">
                    <div className="p-2 flex-fill bd-highlight text-center"><a href="https://www.sinabi.go.cr" target="_blank">Biblioteca Nacional de Costa Rica</a></div>
                    <div className="p-2 flex-fill bd-highlight text-center">Costa Rica</div>
                    <div className="p-2 flex-fill bd-highlight text-center">Tel: (506) 2211-4300 / <a href="http://www.sinabi.go.cr/contactenos/index.aspx" target="_blank">Contacto</a> / <a href="http://www.sinabi.go.cr/privacidad.aspx" target="_blank">Políticas de privacidad</a></div>
                  </div>
                </div>
              </div>
            </div>

            :
            null

        }
      </>
    )
  }

}

const mapStateToProps = (ReduxState) => {
  return {
    showFooter: !ReduxState.nav.timeoutHide,
    MenuItems: ReduxState.nav.MenuItems,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ShowModalAsignaturas: () => {
      //console.log("ShowModalAsignaturas")
      dispatch({ type: 'SHOWMODALASIGNATURAS' })
    },
  }
}

const ConnectedFooter = connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer)



export default ConnectedFooter

